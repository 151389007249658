<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-xl-12"> <b-form-group id="input-group-2" label="ชื่อประเภทเผยแพร่"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="publish_name" placeholder="กรอกชื่อประเภทเผยแพร่"
                                    required></b-form-input>
                            </b-form-group></div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(savePublishType())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import publishTypeService from "../../../../../services/publish-type.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            publishId: null,
            publish_name: null,
            show: true,
            role: null,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
            {
                    text: "บทความ",
                    href: "#",
                },
                {
                    text: "ประเภทเผยแพร่",
                    to: { name: "publishes" },
                },
                {
                    text: "เพิ่มประเภทเผยแพร่",
                    active: true,
                },
            ],
        };
    },

    methods: {
        savePublishType: async function () {
            this.isLoading = true;
            if (this.isValidate()) {
                let newR = {
                    publish_name: this.publish_name,
                }
                await publishTypeService.addPublishType(newR);
                history.back();
            } else {
                this.isLoading = false;
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }

        },
        isValidate() {
            this.errors = [];
            if (!this.publish_name) this.errors.push("Publish Name required.");

            if (!this.errors.length) {
                return true;
            }
            return false;
        },

    },
    mounted() {
    }

};
</script>