import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class PublishService{
    async getPublishTypes(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let publishTypes = await axiosService.get(CONSTANTS.APIURL + `publish-types-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return publishTypes;
    }
    async getPublishTypeById(id) {
        let publishTypes = await axiosService.get(CONSTANTS.APIURL + `publish-type?id=${id}` );
        return publishTypes;
    }
    async addPublishType(...publishType) {
        return await axiosService.post(CONSTANTS.APIURL + 'publish-types', ...publishType);
    }
    async updatePublishType(...publishType) {
        return await axiosService.put(CONSTANTS.APIURL +  'publish-types', ...publishType);
    }
    async deletePublishType(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`publish-types?id=${id}`);
    }
    async getPublishType() {
        return await axiosService.get(CONSTANTS.APIURL +`publish-types`);
    }
}
export default new PublishService();